/***********************************
	Bubbles Module
************************************/
import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

GoodCity.Bubbles = (function () {


	// PRIVATE VARS
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~
	const $toGoLinks = $(".to-go-locations .link");

	// Canvas
	const canvas = document.querySelector('canvas.bubbles')



	// PRIVATE FUNCTIONS
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~

	function _init() {

		/**
		 * Base
		 */
		// Debug
		//const gui = new dat.GUI()

		// Scene
		const scene = new THREE.Scene()
		const axesHelper = new THREE.AxesHelper( 5 );
		//scene.add( axesHelper );

		/**
		 * Textures
		 */
		const textureLoader = new THREE.TextureLoader()
		const particleTexture = textureLoader.load('/Theme/GoodCity/particles/dist/textures/particles/bubble.png')

		/**
		 * Particles
		 */
		// Geometry
		const particlesGeometry = new THREE.BufferGeometry()
		const count = 50

		const positions = new Float32Array(count * 3)
		const colors = new Float32Array(count * 3)

		const scale = 2

		for(let i = 0; i < count * 3; i++)
		{
			if(i % 3 == 2) {
				positions[i] = (Math.random() - 0.5) * .2 * scale
			} else {
				positions[i] = (Math.random() - 0.5) * scale
			}
			colors[i] = 1
		}

		particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
		particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

		// Material
		const particlesMaterial = new THREE.PointsMaterial()

		particlesMaterial.size = 0.1
		particlesMaterial.sizeAttenuation = true

		particlesMaterial.color = new THREE.Color('#b9a066')

		particlesMaterial.transparent = true
		particlesMaterial.alphaMap = particleTexture
		// particlesMaterial.alphaTest = 0.01
		// particlesMaterial.depthTest = false
		particlesMaterial.depthWrite = false
		particlesMaterial.blending = THREE.NormalBlending

		particlesMaterial.vertexColors = true

		// Points
		const particles = new THREE.Points(particlesGeometry, particlesMaterial)
		scene.add(particles)

		/**
		 * Sizes
		 */
		const sizes = {
			width: canvas.offsetWidth,
			height: canvas.offsetHeight
		}

		var resizeBubbles = () => {
			// Update sizes
			sizes.width = canvas.offsetWidth
			sizes.height = canvas.offsetHeight

			// Update camera
			camera.aspect = sizes.width / sizes.height
			camera.updateProjectionMatrix()

			// Update renderer
			renderer.setSize(sizes.width, sizes.height)
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		}

		window.addEventListener('resize', () =>
		{
			resizeBubbles();
		})

		/**
		 * Camera
		 */
		// Base camera
		const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
		camera.position.z = .5
		scene.add(camera)

		// Controls
		//const controls = new OrbitControls(camera, canvas)
		//controls.enableDamping = true

		/**
		 * Renderer
		 */
		const renderer = new THREE.WebGLRenderer({
			canvas: canvas,
			alpha: true
		})
		renderer.setSize(sizes.width, sizes.height)
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		/**
		 * Animate
		 */
		const clock = new THREE.Clock()

		const tick = () =>
		{
			//const elapsedTime = clock.getElapsedTime()
			const deltaTime = clock.getDelta()

			// Update particles
			//for(let i = 0; i < count; i++)
			//{
			//    let i3 = i * 3
		//
			//    const x = particlesGeometry.attributes.position.array[i3]
			//    particlesGeometry.attributes.position.array[i3 + 1] = Math.sin(elapsedTime + x)
			//}
			for(let i = 0; i < count; i++)
			{

				let i1 = (i * 3)
				let i2 = (i * 3) + 1
				let i3 = (i * 3) + 2

				let x = 0

				let y = (deltaTime * .3 + particlesGeometry.attributes.position.array[i2])
				if(y > .5 * scale) {
					y = -.5 * scale;
				}

				let z = particlesGeometry.attributes.position.array[i3]
				
				//particlesGeometry.attributes.position.array[i1] = x
				particlesGeometry.attributes.position.array[i2] = y
				particlesGeometry.attributes.position.array[i3] = z
			}
			particlesGeometry.attributes.position.needsUpdate = true

			// Update controls
			//controls.update()

			// Render
			renderer.render(scene, camera)

			// Call tick again on the next frame
			window.requestAnimationFrame(tick)
		}

		tick()

		/**
		 * Element Switching
		 */
		$toGoLinks.on('mouseover', function() {
			$(this).append(canvas);
			resizeBubbles();
		});
	}



	// PUBLIC METHODS
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~
	return {

		init: _init

	};


})();